import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { CrudOperations } from '@enums/crud-operations.enum';
import {
  FirebaseFirestore,
  GetCollectionOptions,
  QueryCompositeFilterConstraint,
  QueryNonFilterConstraint
} from '@capacitor-firebase/firestore';
import { Injectable, NgZone, inject } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { ToastService } from '@services/toast/toast.service';
import { catchError, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  protected entity: any = new BehaviorSubject<any | null>(null);
  protected entities: any = new BehaviorSubject([]);
  protected collectionListenerAdded = false;
  protected toastEnabled = true;
  protected readonly platform: Platform = inject(Platform);
  protected readonly ngZone: NgZone = inject(NgZone);
  protected readonly toastService: ToastService = inject(ToastService);
  private defaultQueryConstraints = [
    {
      type: 'orderBy',
      fieldPath: 'created',
      directionStr: 'desc'
    }
  ];

  /*
   * Fetch documents from Firestore as a promise
   */
  public getDocuments<T>(
    reference: string,
    compositeFilter?: QueryCompositeFilterConstraint | null | undefined,
    queryConstraints?: QueryNonFilterConstraint[] | null | undefined
  ): Observable<T[]> {
    try {
      const options: GetCollectionOptions = {
        reference,
        compositeFilter: compositeFilter || undefined,
        queryConstraints: queryConstraints || (this.defaultQueryConstraints as QueryNonFilterConstraint[])
      };
      return from(FirebaseFirestore.getCollection(options)).pipe(
        map(document => {
          return document && document.snapshots ? document.snapshots : [];
        }),
        map(snapshots =>
          snapshots.map(snapshot => ({
            ...snapshot.data,
            id: snapshot.id,
            path: snapshot.path
          }))
        ),
        catchError(() => {
          return of([]);
        })
      ) as Observable<T[]>;
    } catch (error) {
      this.showToastError(error);
      return of([]);
    }
  }

  /*
   * Add collection listener for live updates on collection
   */
  public async addCollectionListener(
    reference: string,
    compositeFilter?: QueryCompositeFilterConstraint | null | undefined,
    queryConstraints?: QueryNonFilterConstraint[] | null | undefined
  ): Promise<string | null> {
    try {
      const callbackId = await FirebaseFirestore.addCollectionSnapshotListener(
        {
          reference,
          compositeFilter: compositeFilter || undefined,
          queryConstraints: queryConstraints || (this.defaultQueryConstraints as QueryNonFilterConstraint[])
        },
        (collection: any, error: unknown) => {
          // third-party callback, so we need to run it inside the Angular zone
          this.ngZone.run(() => {
            if (error) {
              console.error('🚀 ~ DataService ~ getCollectionSnapshot ~ error:', error);
            } else {
              this.entities.next(
                collection.snapshots.map((snapshot: any) => ({
                  ...snapshot.data,
                  id: snapshot.id
                }))
              );
            }
          });
        }
      );
      return callbackId;
    } catch (error) {
      this.showToastError(error);
      return null;
    }
  }

  /*
   * Add document listener for live updates on document
   */
  public async addDocumentListener(reference: string): Promise<string | null> {
    try {
      const callbackId = await FirebaseFirestore.addDocumentSnapshotListener(
        {
          reference
        },
        (document: any, error: unknown) => {
          // third-party callback, so we need to run it inside the Angular zone
          this.ngZone.run(() => {
            if (error) {
              console.error('🚀 ~ DataService ~ getDocumentSnapsshot ~ error:', error);
            } else {
              this.entity.next({
                ...document.snapshot.data,
                id: document.snapshot.id
              });
            }
          });
        }
      );
      return callbackId;
    } catch (error) {
      this.showToastError(error);
      return null;
    }
  }

  /*
   * Used to remove document listener
   */
  public async removeSnapshotListener(callbackId: string): Promise<void> {
    await FirebaseFirestore.removeSnapshotListener({
      callbackId
    });
  }

  /*
   * Used to remove all document listeners
   */
  public async removeAllListeners(): Promise<void> {
    await FirebaseFirestore.removeAllListeners();
  }

  /*
   * Fetch document by id from Firestore
   */
  public getDocument<T>(reference: string): Observable<T | null> {
    try {
      return from(FirebaseFirestore.getDocument({ reference })).pipe(
        map(document => (document && document.snapshot ? document.snapshot.data : null))
      ) as Observable<T | null>;
    } catch (error) {
      this.showToastError(error);
      return of(null);
    }
  }

  /*
   * Fetch document by id from Firestore as a promise
   */
  public async getDocumentAsPromise<T>(reference: string): Promise<T | null> {
    const { snapshot } = await FirebaseFirestore.getDocument({ reference });
    return snapshot ? (snapshot.data as T) : null;
  }

  /*
   * Used to create a new document
   */
  public async addDocument<T>(reference: string, entities: string, data: any): Promise<T> {
    try {
      const result = await FirebaseFirestore.addDocument({
        reference,
        data
      });
      const doc = (await this.getDocumentAsPromise(`${reference}/${result.reference.id}`)) as T;
      this.showToast(entities, CrudOperations.CREATE);
      return {
        ...doc,
        ...result.reference
      } as T;
    } catch (error) {
      this.showToastError(error);
      throw error;
    }
  }

  /*
   * Used to update existing document
   */
  public async updateDocument<T>(reference: string, entities: string, data: any): Promise<T> {
    try {
      await FirebaseFirestore.updateDocument({
        reference,
        data
      });
      const result = await this.getDocumentAsPromise(reference);
      this.showToast(entities, CrudOperations.UPDATE);
      return result as T;
    } catch (error) {
      this.showToastError(error);
      throw error;
    }
  }

  /*
   * Used to set a new document or update an existing document
   */
  public async setDocument<T>(reference: string, data: any): Promise<T> {
    try {
      await FirebaseFirestore.setDocument({
        reference,
        data,
        merge: true
      });
      const result = await this.getDocumentAsPromise(reference);
      return result as T;
    } catch (error) {
      this.showToastError(error);
      throw error;
    }
  }

  /*
   * Used to delete an existing document
   */
  public async deleteDocument(reference: string, entity: string): Promise<void> {
    try {
      await FirebaseFirestore.deleteDocument({
        reference
      });
      this.showToast(entity, CrudOperations.DELETE);
      return;
    } catch (error) {
      this.showToastError(error);
      return;
    }
  }

  /*
   * Used to show a toast message, based on the collection and operation
   */
  public showToast(entity: string, operation: CrudOperations): void {
    if (this.toastEnabled) {
      const text = `toast_${entity}_${operation.toLowerCase()}_success`;
      this.toastService.showToast({
        text,
        color: 'success'
      });
    }
  }

  /*
   * Used to show a toast message in case of error
   */
  public showToastError(error: any): void {
    if (this.toastEnabled) {
      this.toastService.showToast({
        text: String(error),
        color: 'danger'
      });
    }
  }
}
