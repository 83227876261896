/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
export enum FirebaseCollections {
  ALERTS = 'alerts',
  CONTACTS = 'contacts',
  DEVICES = 'devices',
  MESSAGES = 'messages',
  PURCHASES = 'purchases',
  SESSIONS = 'sessions',
  SETTINGS = 'settings',
  USERS = 'users'
}
