import { AppConstants } from '@app/app.constants';
import { AuthStateChange, FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { BehaviorSubject, Observable, firstValueFrom, take } from 'rxjs';
import { DataService } from '../data/data.service';
import { FirebaseCollections } from '@app/enums/firebase-collections.enum';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { User, UserDTO } from '@app/models/user';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends DataService {
  public override entity = new BehaviorSubject<User | null>(null);
  protected override toastEnabled = false;
  private readonly http: HttpClient = inject(HttpClient);
  private readonly storageService: StorageService = inject(StorageService);

  /*
   * Used to retrieve stored user data upon app initialization
   */
  public async initialize(): Promise<void> {
    this.removeAllListeners();
    await this.initAuthStateChange();
    const user = await this.storageService.get(AppConstants.USER_DATA);
    if (user) {
      this.entity.next(user);
      await this.addUserListener(user.uid);
    }
  }

  /*
   * Getter method to retrieve auth token of user
   */
  public async getAuthToken(): Promise<string | null> {
    try {
      return (await FirebaseAuthentication.getIdToken()).token;
    } catch {
      return null;
    }
  }

  /*
   *Returns true when user is logged in and email is verified
   */
  public async isAuthenticated(): Promise<boolean> {
    const user = this.entity.value;
    return user !== null ? true : false;
  }

  /*
   * Getter method to retrieve user data from firebase
   */
  public getUser(uid: string): Observable<User | null> {
    return this.getDocument(`${FirebaseCollections.USERS}/${uid}`);
  }

  /*
   * Setter method to save user data in Firestore
   */
  public async setUser(uid: string, data: UserDTO): Promise<void> {
    await this.setDocument(`${FirebaseCollections.USERS}/${uid}`, data);
    const user = await firstValueFrom(this.getUser(uid).pipe(take(1)));
    this.storageService.set(AppConstants.USER_DATA, user);
    return;
  }

  /*
   * Used to update user profile data
   */
  public async updateProfile(data: any): Promise<void> {
    try {
      const currentUser = this.entity.value;
      if (!currentUser) {
        return;
      }
      await this.setUser(currentUser.uid, { ...currentUser, ...data });
    } catch (error: any) {
      this.toastService.showToast({ text: error.toString() });
      throw new Error(error);
    }
  }

  /*
   * Used to delete a user from Firestore, auth, and other collections
   */
  public async deleteUser(uid: string): Promise<void> {
    if (this.entity.value?.uid) {
      const url = `${environment.firebase.httpEndpoint}/${FirebaseCollections.USERS}/${uid}`;
      const token = await this.getAuthToken();
      if (!token) {
        throw new Error('No token found');
      }
      await firstValueFrom(this.http.delete(url, { headers: { Authorization: token } }));
    }
  }

  /*
   * Used to remove all document listeners in case user logs out
   */
  public async removeUserListeners(): Promise<void> {
    await this.removeAllListeners();
  }

  /*
   * Saving user data in localStorage when logged in and setting up null when logged out
   */
  private async initAuthStateChange(): Promise<void> {
    FirebaseAuthentication.addListener('authStateChange', (state: AuthStateChange) => {
      this.ngZone.run(() => {
        if (state.user) {
          this.addUserListener(state.user.uid);
        } else {
          this.entity.next(null);
        }
      });
    });
  }

  /*
   * Used to add document listener for live updates
   */
  private async addUserListener(userId: string): Promise<void> {
    if (userId) {
      await this.addDocumentListener(`${FirebaseCollections.USERS}/${userId}`);
    }
  }
}

export const usersServiceFactory = (usersService: UsersService) => {
  return async (): Promise<void> => await usersService.initialize();
};
